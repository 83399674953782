.siteFooter {
	--background-color: var(--footer-color-background);
	--color: var(--footer-color-text);
	--font-family: var(--footer-typography-text-family);
	--font-size: var(--footer-typography-text-size);
	--font-weight: var(--footer-typography-text-weight);
	--letter-spacing: var(--footer-typography-text-letter-spacing);
	--line-height: var(--footer-typography-text-line-height);

	@media (--bp-medium) {
		--font-family: var(--footer-typography-text-desktop-family);
		--font-size: var(--footer-typography-text-desktop-size);
		--font-weight: var(--footer-typography-text-desktop-weight);
		--letter-spacing: var(--footer-typography-text-desktop-letter-spacing);
	}

	background-color: var(--background-color);
	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: var(--spacing-xl) var(--spacing-2xs);
	position: relative;
	text-align: left;
	z-index: 50;

	@media (--bp-medium) {
		padding: var(--spacing-xl-desktop) var(--spacing-l-desktop);
	}

	& a,
	& button {
		--color: var(--footer-color-link);
		--color-hover: var(--footer-color-link-hover);
		--color-active: var(--footer-color-link-active);
		--color-focus: var(--footer-color-link-focus);
		--outline-width: var(--footer-typography-link-focus-outline-width);
		--outline-style: var(--footer-typography-link-focus-outline-style);
		--outline-color: var(--footer-typography-link-focus-outline-color);
		--font-family: var(--footer-typography-link-family);
		--font-size: var(--footer-typography-link-size);
		--font-weight: var(--footer-typography-link-weight);
		--letter-spacing: var(--footer-typography-link-letter-spacing);

		@media (--bp-medium) {
			--line-height: var(--footer-typography-text-desktop-line-height);
			--font-family: var(--footer-typography-link-desktop-family);
			--font-size: var(--footer-typography-link-desktop-size);
			--font-weight: var(--footer-typography-link-desktop-weight);
		}
	}
}

.siteFooterContainer {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-l);
	padding-bottom: var(--footer-ad-height);

	@media (--bp-medium) {
		gap: var(--spacing-s-desktop);
	}

	@media (--allows-motion) {
		transition: padding-bottom var(--transition-speed-fast) ease-in-out;
	}
}

.siteFooterTop {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: var(--spacing-m);

	@media (--bp-medium) {
		align-items: center;
		column-gap: var(--spacing-2xl-desktop);
		flex-direction: row;
		row-gap: 0;
	}
}

.siteFooterTopCopy {
	border-bottom: 1px solid var(--color-neutral-light-10);
	flex: 1;
	font-size: inherit;
	padding-bottom: var(--spacing-l);
	text-align: center;

	@media (--bp-medium) {
		border-bottom: none;
		padding-bottom: 0;
		text-align: left;
	}
}

.siteFooterLogo {
	color: inherit;
	transition: color var(--transition-speed-fast) ease-in-out;

	& img {
		display: block;
	}
}

.siteFooterCategories {
	--border-color: var(--footer-separator-color);
	--border-width: var(--footer-separator-thickness, 1px);

	border-top: var(--border-width) solid var(--border-color);
	display: grid;
	flex-wrap: wrap;
	gap: 0 var(--spacing-xl);
	grid-template-columns: repeat(1, 1fr);
	list-style: none;
	padding: 0;

	@media (--bp-medium) {
		border-top: 0;
		gap: var(--spacing-m-desktop) var(--spacing-xl-desktop);
		grid-template-columns: repeat(var(--menu-columns), 1fr);

		& :global(.accordion__panel) {
			display: none;
		}
	}
}

:global(.accordion__panel) + .siteFooterCategories {
	display: none;

	@media (--bp-medium) {
		display: block;
	}
}

.siteFooterCategoriesItem {
	--font-family: var(--footer-typography-link-l1-family);
	--font-size: var(--footer-typography-link-l1-size);
	--font-weight: var(--footer-typography-link-l1-weight);
	--line-height: var(--footer-typography-link-l1-line-height);
	--letter-spacing: var(--footer-typography-link-l1-letter-spacing);

	border-bottom: var(--border-width) solid var(--border-color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	@media (--bp-medium) {
		--font-family: var(--footer-typography-link-l1-desktop-family);
		--font-size: var(--footer-typography-link-l1-desktop-size);
		--font-weight: var(--footer-typography-link-l1-desktop-weight);
		--line-height: var(--footer-typography-link-l1-desktop-line-height);
		--letter-spacing: var(--footer-typography-link-l1-desktop-letter-spacing);

		border-bottom: none;
	}

	& ul {
		border-top: 0;
		list-style: none;
		padding-bottom: var(--spacing-m);

		@media (--bp-medium) {
			padding: 0;
		}
	}

	& li {
		border-bottom: 0;
		margin: var(--spacing-3xs) 0;
		padding-bottom: 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@media (--bp-medium) {

			&:first-child {
				margin-top: var(--spacing-xs);
			}

			margin: var(--spacing-3xs-desktop) 0;
		}
	}

	& li a.siteFooterCategoriesLink {
		--font-family: var(--footer-typography-link-l2-family);
		--font-size: var(--footer-typography-link-l2-size);
		--font-weight: var(--footer-typography-link-l2-weight);
		--line-height: var(--footer-typography-link-l2-line-height);
		--letter-spacing: var(--footer-typography-link-l2-letter-spacing);

		display: inline-flex;
		padding: 0;

		@media (--bp-medium) {
			--font-family: var(--footer-typography-link-l2-desktop-family);
			--font-size: var(--footer-typography-link-l2-desktop-size);
			--font-weight: var(--footer-typography-link-l2-desktop-weight);
			--line-height: var(--footer-typography-link-l2-desktop-line-height);
			--letter-spacing: var(--footer-typography-link-l2-desktop-letter-spacing);
		}
	}
}

a.siteFooterCategoriesLink {
	--font-family: var(--footer-typography-link-l1-family);
	--font-size: var(--footer-typography-link-l1-size);
	--font-weight: var(--footer-typography-link-l1-weight);
	--line-height: var(--footer-typography-link-l1-line-height);
	--letter-spacing: var(--footer-typography-link-l1-letter-spacing);

	color: inherit;
	display: block;
	padding: var(--spacing-xs) 0 var(--spacing-2xs);

	@media (--bp-medium) {
		--font-family: var(--footer-typography-link-l1-desktop-family);
		--font-size: var(--footer-typography-link-l1-desktop-size);
		--font-weight: var(--footer-typography-link-l1-desktop-weight);
		--line-height: var(--footer-typography-link-l1-desktop-line-height);
		--letter-spacing: var(--footer-typography-link-l1-desktop-letter-spacing);

		padding: 0;
	}

	&:global(.accordion-hidden) {
		display: none;

		@media (--bp-medium) {
			display: inline-block;
		}
	}
}

.siteFooterCategoriesLink {

	&:global(.accordion-button) {
		--font-family: var(--footer-typography-link-l1-family);
		--font-size: var(--footer-typography-link-l1-size);
		--font-weight: var(--footer-typography-link-l1-weight);
		--line-height: var(--footer-typography-link-l1-line-height);
		--letter-spacing: var(--footer-typography-link-l1-letter-spacing);

		align-items: center;
		appearance: none;
		background-color: transparent;
		border: none;
		color: var(--color);
		display: flex;
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		justify-content: space-between;
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		padding: var(--spacing-xs) 0;
		width: 100%;

		@media (--bp-medium) {
			--font-family: var(--footer-typography-link-l1-desktop-family);
			--font-size: var(--footer-typography-link-l1-desktop-size);
			--font-weight: var(--footer-typography-link-l1-desktop-weight);
			--line-height: var(--footer-typography-link-l1-desktop-line-height);
			--letter-spacing: var(--footer-typography-link-l1-desktop-letter-spacing);
		}
	}

	&:global(.accordion-visible) {
		display: flex;

		@media (--bp-medium) {
			display: none;
		}
	}
}

.siteFooterList {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-2xs);
	justify-content: left;
	list-style: none;
	padding: 0;

	@media (--bp-medium) {
		gap: var(--spacing-2xs-desktop);
	}
}

.siteFooterListItem {
	align-items: center;
	display: flex;
	gap: inherit;
}

.siteFooterListItemLink {
	color: inherit;
	text-decoration: none;
}

.siteFooterLower {
	align-items: center;
	display: grid;
	gap: var(--spacing-s) var(--spacing-2xs);
	grid-template-columns: 1fr 160px;
	grid-template-rows: auto auto;
	justify-content: space-between;

	@media (--bp-medium) {
		grid-template-columns: 1fr 160px;
	}

	.siteFooterWithTicker & {
		grid-template-columns: 1fr 160px;
		grid-template-rows: auto auto auto;

		@media (--bp-medium) {
			grid-template-columns: 1fr 145px 160px;
		}
	}

	& > nav {
		grid-column: span 2;

		@media (--bp-medium) {
			grid-column: 1;
		}
	}

	& img {
		height: auto;
		max-width: 100%;
	}
}

.siteFooterWithTicker .siteFooterLower > nav {
	grid-column: 1 / span 2;

	@media (--bp-medium) {
		grid-column: 1;
	}
}

.siteFooterCopyright {
	font-size: inherit;
	margin-bottom: 0;
	text-wrap: balance;

	.siteFooterWithTicker & {
		grid-column: 1 / span 2;

		@media (--bp-medium) {
			grid-column-end: 2;
			grid-column-start: 1;
		}

	}
}

.siteFooterGroupLogo {
	color: var(--color-neutral-white);

	@media (--bp-medium) {
		grid-column-start: 2;
		grid-row: span 2 / span 2;
		grid-row-start: 1;
	}

	.siteFooterWithTicker & {
		grid-column-start: 2;
		grid-row-start: 3;

		@media (--bp-medium) {
			grid-column-start: 3;
			grid-row-end: span 2;
			grid-row-start: 1;
		}
	}
}

.siteFooterGroupTicker {
	grid-column-start: 1;
	grid-row-start: 3;

	@media (--bp-medium) {
		grid-column-start: 2;
		grid-row-end: span 2;
		grid-row-start: 1;
	}

	& span[class="qmod-volume-group"] {
		display: none;
	}

	& span[class="qmod-segment qmod-longname"] {
		display: none;
	}

	& span[class="qmod-segment qmod-last"]::before {
		content: "$";
	}

	& span[class="qmod-segment qmod-datetime"] {
		color: var(--color);
		display: block;
	}
}

.siteFooterArenaLink {
	display: inline-block;
}

.siteFooterSeparator {
	--border-color: var(--footer-separator-color);
	--border-size: var(--footer-separator-thickness, 1px);

	display: none;

	@media (--bp-medium) {
		border-top: var(--border-size) solid var(--border-color);
		display: block;
		margin: var(--spacing-xs) 0;
		opacity: 10%;
	}
}
